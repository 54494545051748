/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/views/layouts/index'),
    redirect: '/home',
    meta: {
      title: '首页',
      keepAlive: false
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', keepAlive: false }
      },
      {
        path: '/list',
        name: 'List',
        component: () => import('@/views/home/list'),
        meta: { title: '列表', keepAlive: false }
      },
      {
        path: '/vant',
        name: 'Vant',
        component: () => import('@/views/home/vant'),
        meta: { title: 'vant组件', keepAlive: false }
      },
      {
        path: '/sign',
        name: 'Sign',
        component: () => import('@/views/home/sign'),
        meta: { title: 'sign', keepAlive: false }
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/home/about'),
        meta: { title: '关于我', keepAlive: false }
      },
      {
        path: '/list',
        name: 'List',
        component: () => import('@/views/pages/pages1'),
        meta: { title: '列表', keepAlive: false }
      },
      {
        path: '/coursedetailxcx',
        name: 'coursedetailxcx',
        component: () => import('@/views/home/coursedetailxcx'),
        meta: { title: '课程', keepAlive: false }
      },
      {
        path: '/buy',
        name: 'buy',
        component: () => import('@/views/home/buy'),
        meta: { title: '支付', keepAlive: false }
      }
    ]
  }
]

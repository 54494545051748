/**
 * Created by Administrator on 2018/11/23 0023.
 */
import axios from 'axios'
import router from './router'
import { getStore, setStore } from './storage'
import { refreshtoken } from './http'
// import { Toast } from 'we-vue'
// 统一请求路径前缀
// let base = '/base'
// 超时设定
axios.defaults.timeout = 50000
axios.interceptors.request.use(
  config => {
    return config
  },
  err => {
    // Toast.fail('请求超时')
    return Promise.resolve(err)
  }
)

// //test
// app.all('*', function(req, res, next) {
//   res.header("Access-Control-Allow-Origin", "*")
//   res.header("Access-Control-Allow-Headers", "X-Requested-With,Content-Type")
//   res.header("Access-Control-Allow-Methods","PUT,POST,GET,DELETE,OPTIONS")
//   next()
// })
// test

// http response 拦截器
axios.interceptors.response.use(
  response => {
    const data = response.data
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {
      case 401:
        // 未登录 清除已登录状态
        /* setStore('userInfo', '')
      setStore('token', '')*/
        router.push('/')
        // token过期，重新获取token
        /* refreshtoken({refresh_token:getStore('refresh_token'),client_id:'gateway',client_secret:'123456',grant_type:'refresh_token'}).then(res => {
        if(res.access_token !== undefined){
          setStore('token',res.access_token)
        }else {
          router.push('/')
        }
      })*/
        break
      case 403:
        // 没有权限
        if (data.message !== null) {
          // Toast.fail(data.message)
        } else {
          // Toast.fail("未知错误")
        }
        break
      case 500:
        // 错误
        if (data.message !== null) {
          // Toast.fail(data.message)
        } else {
          // Toast.fail("未知错误")
        }
        break
      default:
        return data
    }

    return data
  },
  err => {
    // 返回状态码不为200时候的错误处理
    // Toast.fail(err.toString())
    return Promise.resolve(err)
  }
)

export const getRequest = (url, params) => {
  let token = getStore('token')
  return axios({
    method: 'get',
    url: `${url}`,
    params: params,
    headers: {
      Authorization: token
      // 'Authorization': 'Bearer 943d0488-33bc-4270-b298-88484ed1800b'
    }
  })
}

export const postRequest = (url, params) => {
  let token = getStore('token') + ''
  console.info('token:' + token)
  return axios({
    method: 'post',
    url: `${url}`,
    data: params,
    transformRequest: [
      function(data) {
        let ret = ''
        for (let it in data) {
          ret +=
            encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: token
      // 'Authorization': 'Bearer 943d0488-33bc-4270-b298-88484ed1800b'
    }
  })
}
export const postRequestByJson = (url, params) => {
  let token = getStore('token') + ''
  let accountId = getStore('accountId') + ''
  return axios({
    method: 'post',
    url: `${url}`,
    data: params,
    headers: {
      Authorization: token,
      // accountId: accountId,
      accountId: 1
    }
  })
}
export const putRequest = (url, params) => {
  let token = getStore('token')
  return axios({
    method: 'put',
    url: `${url}`,
    data: params,
    transformRequest: [
      function(data) {
        let ret = ''
        for (let it in data) {
          ret +=
            encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }
    ],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: token
    }
  })
}

export const deleteRequest = (url, params) => {
  let token = getStore('token')
  return axios({
    method: 'delete',
    url: `${url}`,
    params: params,
    headers: {
      Authorization: token
    }
  })
}

export const uploadFileRequest = (url, params) => {
  let token = getStore('token')
  return axios({
    method: 'post',
    url: `${url}`,
    params: params,
    headers: {
      // 'Authorization': token
      Authorization: 'Bearer 943d0488-33bc-4270-b298-88484ed1800b'
    }
  })
}

<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import './api/index.js' // 导入api

export default {
    name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="less">
body {
    background-color: #f7f8fa;
}

.form-item {
    .label {
        margin: 10px 16px 5px;
        color: #990030;
        font-size: 14px;
    }
}

.page-box {
    height: 89vh;
    padding: 10px;

    .container {
        padding-bottom: 60px;
    }
}</style>

import Vue from 'vue'
import { http } from '../http.js'

Vue.prototype.$api = {
  // 登录
  loginxxx: params => { return http.get(`/user/queryUser`, { params: params }) },

  // docker 创建
  addDockerxxx: params => { return http.post(`/docker/saveDocker`, params) },

  // docker 删除
  deleteDockerxxx: params => { return http.delete(`/docker/deleteDocker`, { params: params }) }
}

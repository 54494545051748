/**
 * Created by Administrator on 2018/11/28 0028.
 */
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  uploadFileRequest,
  postRequestByJson
} from './api'
export const getExamData = (params = {}) => {
  return postRequestByJson('/api/exam/third/query/examInfo', params)
}
export const addOrder = params => {
  return postRequest('/base/order/addOrder', params)
}
export const collecon = params => {
  return postRequest('/base/attention/collecon', params)
}
export const coursetypeWeb = params => {
  return postRequest('/base/common/pc/coursetypeWeb', params)
}
export const courseDetail = params => {
  return postRequest('/base/common/pc/courseDetail', params)
}
// 发表评论
export const reply = params => {
  return postRequest('/base/attention/reply', params)
}
// 获取评论
export const getReplysWeb = params => {
  return postRequest('/base/common/pc/getReplysWeb', params)
}
export const centreInfoWeb = params => {
  return postRequest('/base/pcAbility/centreInfoWeb', params)
  // let Obj = { code: 200 };
  // return Promise.resolve(Obj);
}
export const orderbuyPageWeb = params => {
  return postRequest('/base/pcOrder/orderbuyPageWeb', params)
}
// 订单支付成功页面
export const orderSuccess = params => {
  return postRequest('/base/common/pc/orderSuccess', params)
}

// 支付二维码生成（支付宝微信）
export const toQrPay = params => {
  return postRequest('/base/common/toQrPay', params)
}
// 支付订单状态查询（支付过程中）
export const payorderStatusWeb = params => {
  return postRequest('/base/pcOrder/payorderStatusWeb', params)
}
// 学习币支付
export const balancePayWeb = params => {
  return postRequest('/base/pcOrder/balancePayWeb', params)
}
// 我的计划
export const myPlanPageWeb = params => {
  return postRequest('/base/pcAbility/myPlanPageWeb', params)
}
// 计划详情
export const myPlanDetail = params => {
  return postRequest('/base/pcAbility/myPlanDetail', params)
}
// 更新学习计划
export const sendPlan = params => {
  return postRequest('base/videoLearnRecord/save', params)
}
// 查询学习计划
export const queryLearnRecord = params => {
  return postRequest('base/videoLearnRecord/query', params)
}
export const weChatPay = params => {
  return postRequest('base/pay/weChatPay', params)
}
export const getPsign = params => {
  return postRequest('base/third/psin', params)
}
export const verifyCanPlayVideo = params => {
  return getRequest('base/third/verifyCanPlayVideo', params)
}
export const collectAdd = params => {
  return postRequest('base/collect/add', params)
}
export const uploadSign = params => {
  return getRequest('base/file/upload/parameter', params)
}
